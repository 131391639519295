import React from 'react';
import styled from 'styled-components';
import Title from './../components/Title';
import { InnerLayout } from './../styles/Layouts';
import image from './../images/pfp.png';
import PrimaryButton from './../components/PrimaryButton';
import Carousel from 'react-elastic-carousel';
import TestimonialItem from './../components/TestimonialItem';
import ss2 from './../images/Projects/ss2.png';
import ss1 from './../images/Projects/ss1.png';
import mintlfg from './../images/Projects/mintlfg.png';
import mintlfg_mobile from './../images/Projects/mintlfg_mobile.png';
import no12 from './../images/Projects/no12.png';
import tgbot from './../images/Projects/tgbot.png';

function AboutPage() {
    return (
        <AboutPageStyled>
            {/* about Start Here */}
            <Title title={'About'} span={'Me'} />
            <InnerLayout className="about-section">  
                <div className="image-part">
                    <img src={image} alt="" />
                </div>
                <div className="info-part">
                    <h4>Who am i?</h4>
                    <h3>I am <span>Sebastian</span></h3>
                    <p>I am full stack web developer. I have Experience in Developing Websites, Web Applications, Solidity Contract, Telegram Bot. </p>
                    <p>I have been in Web3 for close to 2 years, during which I have independently developed NFT/Erc20 Tools, NFT Websites, OTC business Websites, Telegram bots, NFT/Erc20 Mint Contracts and Bulk Mint Contracts in Solidity.</p>
                    <div className="information">
                        <ul>
                            <li>Name</li>
                            <li>Age</li>
                            <li>Nationality</li>
                            <li>Languages</li>
                            <li>Freelance</li>
                        </ul>
                        <ul className="details">
                            <li>:</li>
                            <li>:</li>
                            <li>:</li>
                            <li>:</li>
                            <li>:</li>
                        </ul>
                        <ul className="details">
                            <li>Sebastian</li>
                            <li>27 Years</li>
                            <li>China</li>
                            <li>English , Chinese</li>
                            <li>Available</li>
                        </ul>
                    </div>
                    <a href="./SebastianZhang_Front-end.pdf" download target='__blank'><PrimaryButton btn={'Download Cv'} /></a>
                </div>
            </InnerLayout>
            {/* About End Here */}

            {/* Projects Start Here */}
            <div className="Projects">
                <Title title={'Projects'} span={''} />
                <InnerLayout>
                    <Carousel>
                        <TestimonialItem text={'An NFT Website inspired by the Windows 98 theme, featuring project descriptions and Mint functionality.'} img={ss2} />
                        <TestimonialItem text={'A well-designed and beautifully crafted NFT Website, featuring project descriptions and Mint functionality.'} img={ss1} />
                        <TestimonialItem text={'A professional Tool Website for NFT, Erc20, and other assets, integrating Data Streaming, Mint Functionality, and Order-Based Transactions.'} img={mintlfg} />
                        <TestimonialItem text={'A professional Tool Website for NFT, Erc20, and other assets, integrating Data Streaming, Mint Functionality, and Order-Based Transactions. But Build For Mobile Phone'} img={mintlfg_mobile} />
                        <TestimonialItem text={'An OTC Business Website with features such as trade execution, order placement, auditing, and a dashboard for data visualization and data entry.'} img={no12} />
                        <TestimonialItem text={'A Telegram Dashboard and Data Entry Bot using the Telegram API.'} img={tgbot} />
                    </Carousel>
                </InnerLayout>
            </div>
            {/* Projects End Here */}
        </AboutPageStyled>
    )
}


const AboutPageStyled = styled.div`
    .about-section{
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap:.8rem;
        
        .image-part{
            width:100%;
            height: 30rem;
            img{
                height:30rem;
                width:30rem; 
                border-radius: 20px;
                border: 0px solid var(--border-color-4);
            }
        }
        .info-part{
            h4{
                color:var(--primary-color);
                font-size:1.3rem;
            }
            h3{
                margin:1rem 0;
                font-size:1.9rem;
                span{
                    color:var(--primary-color);
                }
            }
            p{
                padding: 0rem 0 1rem 0;
                font-size:.9rem;
            }
            .information{
                margin:.5rem 0;
                width:90%;
                display:flex;
                justify-content: space-between;
                @media screen and (max-width:1200px){
                    width:100%;
                }
                ul{
                    li{
                        margin:.5rem 0;
                        @media screen and (max-width:1200px){
                            margin:1rem 0;
                            font-size:.9rem;
                        }
                    }
                }
                .details{
                    color:var(--paragraph-color);
                }
            }
           
        }
        @media screen and (max-width:1200px){
            grid-template-columns: repeat(1, 1fr);
            grid-gap:1.5rem;

            .image-part{
                width:100%; 
                height:20rem;
                img{
                    height:20rem;
                    width:20rem; 
                    border-radius: 20px;
                    border: 0px solid var(--border-color-4);
                }
            }
        }
    } 
    .rec-arrow{
        background-color: var(--border-color);
        &:hover{
            background-color: var(--primary-color);
        }
        &:active,&:focus{
            background-color: var(--primary-color);
        }
    }
    .rec-dot_active{
            box-shadow:none;
            background-color:var(--primary-color);
    }
    @media screen and (max-width:1200px){
        .rec.rec-arrow{
            display:none;
        } 
    }
`;

export default AboutPage;
