import React from 'react';
import styled from 'styled-components';

function TestimonialItem({ text, img }) {
    return (
        <TestimonialItemStyled>
            <p> {text}</p>
            <div className="client-img">
                <img src={img} alt="" />
            </div>
        </TestimonialItemStyled>
    )
}

const TestimonialItemStyled = styled.div`
    background-color: var(--border-color);
    margin:1rem 0;
    width:100%;
    margin:0.5rem auto;
    padding:2.5rem;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    flex-direction: column; 
    p{
        margin:.5rem 0;
        text-align:center;
    }
    .client-img{
        margin-top:1rem; 
        display:flex;
        align-items:center;
        justify-content:center;
        img{
            border:2px solid var(--border-color);
            width:50rem; 
            border-radius:10px;
        }
    }
    @media screen and (max-width:1200px){ 
        display:flex;
        align-items:center;
        justify-content:flex-start;
        height:30rem;
        .client-img{ 
            margin-top:1rem; 
            display:flex;
            align-items:center;
            justify-content:center;
            img{
                width:100%; 
                border:2px solid var(--border-color);
                border-radius:20px;
            }
        }
    }
`;

export default TestimonialItem;
