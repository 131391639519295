import { createGlobalStyle } from "styled-components";
import pfp from "./../images/pfp.png";

const GlobalStyle = createGlobalStyle`
    .dark-mode{
        --primary-color:#a8cbff;
        --background-color:#090e14;
        --sidebar-color:#121621;
        --font-color:#e8eef9;
        --paragraph-color:#a8cbff;
        --border-color:transparent;
        --border-color-2:transparent;
        --border-color-3:#191919;
        --border-color-4:#4c4c4c;
        --button-color:#1988f7;
        --logo-color:#ffffff;
        --logo-image: url(${pfp});
        --tw-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -2px rgba(0,0,0,.1);
        --tw-bottom-shadow: 6px -4px 6px -1px rgba(0,0,0,.1);
        --tw-ring-shadow: 0 0 #0000;
        --tw-ring-offset-shadow: 0 0 #0000;
        --text-2-color:#a8cbff;
        --logo-font:'Monoton', cursive;
    }
    .light-mode{
        --primary-color:#1f2334;
        --background-color:#e9eef4;
        --sidebar-color:#fdfdff;
        --font-color:#51657e;
        --paragraph-color:#51657e;
        --border-color:transparent;
        --border-color-2:transparent;
        --border-color-3:#ccc;
        --border-color-4:#b2b2b2;
        --button-color:#1988f7;
        --logo-color:#09062b;
        --logo-image: url(${pfp});
        --tw-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -2px rgba(0,0,0,.1);
        --tw-bottom-shadow: 6px -4px 6px -1px rgba(0,0,0,.1);
        --tw-ring-shadow: 0 0 #0000;
        --tw-ring-offset-shadow: 0 0 #0000;
        --text-2-color:#a8cbff;
        --logo-font:'Monoton', cursive;
    }
    *{
        margin: 0;
        padding: 0;
        box-sizing:border-box;
        list-style: none;
        text-decoration: none;
        font-family: 'Prompt', sans-serif;
    }
    body{
        background-color: var(--background-color);
        color:var(--font-color);
    }
    body::-webkit-scrollbar{
        background: #383838;
        width: 9px;
    }
    body::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background: #6b6b6b;
    }
    body::-webkit-scrollbar-track{
        border-radius: 10px;
        background:#383838;
    }

    h1,h2,h3,h4,h5,h6{
        font-family: 'Mulish', sans-serif; 
        font-weight: 900;
    }
    h1{
        font-size:3rem;
        @media screen and (max-width:768px){
            font-size:2.4rem;
        }
        @media screen and (max-width:625px){
            font-size:2rem;
        }
        @media screen and (max-width:300px){
            font-size:1.8rem;
        }
        span{
            color:var(--primary-color);
            font-size:3rem;
            @media screen and (max-width:768px){
                font-size:2.4rem;
            }
            @media screen and (max-width:625px){
                font-size:2rem;
            }
            @media screen and (max-width:300px){
                font-size:1.8rem;
            }
        }
    }
    a{
        font-family:inherit;
        color: inherit;
    }
    button{
        font-family: 'Montserrat', sans-serif;
        outline:none;
        border:none;
    }
    p{
        letter-spacing:.1rem;
        word-spacing:.1rem;
        color:var(--paragraph-color);
        font-size:1rem;
        @media screen and (max-width:625px){
            font-size:.9rem;
        }
    }

    .fixed-top-area {
        .logo{
            width:100%;
            height: 5rem;
            border-bottom: 1px solid var(--border-color);
            text-align:flex-end;
            display:flex;
            padding: 0.5rem 1rem;
            align-items:center;
            justify-content:flex-start;

            .logo-image{
                height:4rem;
                width:4rem; 
                border-radius: 50%;
                border: 2px solid var(--border-color-4);
                background-size: contain; 
                background-repeat: no-repeat; 
            }

            p{
                font-family: var(--logo-font);
                font-size:4rem;
                font-weight: 300;
                letter-spacing: 0px;
                padding: 0 0.5rem;
                display: none;
                color:var(--logo-color);
                &:hover{
                    cursor: pointer;
                }
            }
        }

        /* Toggler Menu */
        .hamburger-bar{
            position: absolute;
            right:0.9rem;
            top:0%;
            z-index:15;
            display:none;
            transition: background-color 0.15s linear;
            svg{
                font-size:2rem;
                color:var(--button-color);
            }
        }
        .hamburger-bar:active {
            background-color: rgba(66, 143,	177, 0.5);
        }
        @media screen and (max-width:1200px){
            position: fixed;
            top: 0;
            left: 0; 
            width: 100%;
            height: 3rem;
            background-color: rgba(var(--sidebar-color), 0.8);
            z-index: 5; 
            backdrop-filter: blur(15px);  
            box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
            &.top{
                background-color: rgba(var(--background-color), 1); 
            }
            .hamburger-bar{
                display:block;
            }
            .logo{
                width:100%;
                height: 3rem;
                border-bottom: 1px solid var(--border-color);
                text-align:flex-end;
                display:flex;
                padding: 0.5rem 1rem;
                align-items:center;
                justify-content:flex-start;

                .logo-image{
                    height:2rem;
                    width:2rem; 
                    border-radius: 50%;
                    border: 2px solid var(--border-color-4);
                    background: var(--logo-image);
                    background-size: contain; 
                    background-repeat: no-repeat; 
                }

                p{
                    font-family: var(--logo-font);
                    font-size:1.6rem;
                    font-weight: 300;
                    letter-spacing: 0px;
                    padding: 0 0.5rem;
                    display: block;
                    color:var(--logo-color);
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
        }  
    }

    .fixed-bottom-area { 
        position: fixed;
        bottom: 0;
        left: 16.3rem; 
        width: 100%;
        height: 3rem;
        background-color: var(--sidebar-color);
        z-index: 5; 
        box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-bottom-shadow);
        p{
            font-size:4rem;
            font-weight: 300;
            letter-spacing: 0px;
            padding: 0 0.25rem;
            display: block;
            color:var(--logo-color);
            &:hover{
                cursor: pointer;
            }
        }
         
        @media screen and (max-width:1200px){
            position: fixed;
            bottom: 0;
            left: 0; 
            width: 100%;
            height: 3rem;
            background-color: var(--sidebar-color);
            z-index: 5; 
            box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-bottom-shadow);
            p{
                font-size:2rem;
                font-weight: 300;
                letter-spacing: 0px;
                padding: 0 0.25rem;
                display: block;
                color:var(--logo-color);
                &:hover{
                    cursor: pointer;
                }
            }
        }  
    }

    .nav-logo{
        width:100%;
        height: 10rem;
        border-bottom: 1px solid var(--border-color);
        text-align:flex-end;
        display:flex;
        padding: 0.5rem 1rem;
        align-items:center;
        justify-content:center;

        .nav-logo-image{
            height:8rem;
            width:8rem; 
            margin-top:4rem;
            border-radius: 50%;
            border: 2px solid var(--border-color-4);
            background: var(--logo-image);
            background-size: contain; 
            background-repeat: no-repeat; 
        }

        p{
            font-family: var(--logo-font);
            font-size:1.5rem;
            font-weight: 300;
            letter-spacing: 0px;
            padding: 0 0.5rem;
            display: block;
            color:var(--logo-color);
            &:hover{
                cursor: pointer;
            }
        }
    }

    /* Toggler Menu */
    .hamburger-bar{
        position: absolute;
        right:0.9rem;
        top:0%;
        z-index:15;
        display:none;
        transition: background-color 0.15s linear;
        svg{
            font-size:2rem;
            color:var(--button-color);
        }
    }
    .hamburger-bar:active {
        background-color: rgba(66, 143,	177, 0.5);
    }
    @media screen and (max-width:1200px){
        .hamburger-bar{
            display:block;
        }
        .nav-logo{
            width:100%;
            height: 6rem;
            border-bottom: 1px solid var(--border-color);
            text-align:flex-end;
            display:flex;
            padding: 0.5rem 1rem;
            align-items:center;
            justify-content:center;

            .nav-logo-image{
              height:5rem;
              width:5rem; 
              margin-top:4rem;
              border-radius: 50%;
              border: 2px solid var(--border-color-4);
              background: var(--logo-image);
              background-size: contain; 
              background-repeat: no-repeat; 
            }

            p{
              font-family: var(--logo-font);
              font-size:1.6rem;
              font-weight: 300;
              letter-spacing: 0px;
              padding: 0 0.5rem;
              display: block;
              color:var(--logo-color);
              &:hover{
                cursor: pointer;
              }
            }
        }
    }

    .Nav-toggle{
        transform:translateX(0);
        z-index:20;
    }
`;

export default GlobalStyle;