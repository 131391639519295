import { useState, useEffect } from 'react';
import SideBar from './components/Sidebar';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ServicePage from './pages/ServicePage';
import ResumePage from './pages/ResumePage';
import PortfolioPage from './pages/PortfolioPage';
import BlogPage from './pages/BlogPage';
import ContactPage from './pages/ContactPage';
import BlogPost from './components/BlogPost';
import { Switch as Switching, Route, Redirect } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import styled from 'styled-components';
import {MainLayout} from './styles/Layouts';
function App() {
  const [isNavToggle, setNavToggle] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const fixedTopArea = document.querySelector('.fixed-top-area');
      
      if (fixedTopArea) {
        if (window.scrollY === 0) {
          fixedTopArea.classList.add('top');
        } else {
          fixedTopArea.classList.remove('top');
        }
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <SideBar isNavToggle={isNavToggle} setNavToggle={setNavToggle} />
      
      <div className="fixed-top-area">
        <div className="logo">
          <div className="logo-image"></div>
        </div>

        {/* Toggler Button for navbar*/}
        <div className="hamburger-bar">
          <IconButton onClick={() => setNavToggle(!isNavToggle)}>
            <MenuIcon />
          </IconButton>
        </div>
      </div>

      {/* Main Section Start */}
      <MainSectionStyled>
      <MainLayout>
        <Switching>
          <Route path="/" exact> <HomePage /> </Route>
          <Route path="/about" exact> <AboutPage /> </Route>
          <Route path="/services" exact> <ServicePage /> </Route>
          <Route path="/resume" exact> <ResumePage /> </Route>
          <Route path="/portfolio" exact> <PortfolioPage /> </Route>
          <Route path="/blog" exact> <BlogPage /> </Route>
          <Route path="/blogPost" exact> <BlogPost /> </Route>
          <Route path="/contact" exact> <ContactPage /> </Route>
          <Redirect to="/" />
        </Switching>
        </MainLayout>
      </MainSectionStyled>
  
    </div>
  );
}

const MainSectionStyled = styled.main`
    margin-left: 16.3rem;
    min-height: 100vh;
 
    @media screen and (max-width:1200px){
      margin-left: 0;
    }
`;

export default App;
