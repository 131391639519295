import React from 'react';
import styled from 'styled-components';
import Title from './../components/Title';
import { InnerLayout } from './../styles/Layouts';
import PrimaryButton from './../components/PrimaryButton';
import ContactInfoItem from './../components/ContactInfoItem';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function ContactPage() {
    const phone = <PhoneIcon />
    const email = <EmailIcon />
    const location = <LocationOnIcon />
    const twitter = <TwitterIcon />
    const github = <GitHubIcon />
    const linkedin = <LinkedInIcon />

    return (
        <ContactPageStyled>
            <Title title={'Contact'} span={'Me'} />
            <InnerLayout className="contact-section">
                <div className="contact-info">
                    <h4>Contact Info</h4> 
                    <ContactInfoItem icon={email} heading={'Email Me'} text={'whalefrat@gmail.com'} url={'https://mail.google.com/mail/?view=cm&fs=1&to=whalefrat@gmail.com'}/>
                    <ContactInfoItem icon={twitter} heading={'DM Me'} text={'0xSebastianNFT'} url={'https://twitter.com/0xSebastianNFT'}/>
                    <ContactInfoItem icon={github} heading={'Find Me'} text={'whalefrat'} url={'https://github.com/whalefrat'}/>
                    <ContactInfoItem icon={linkedin} heading={'Find Me'} text={'Sebastian'} url={'https://www.linkedin.com/in/sebastian-zhang-180419293/'}/>
                </div>
                <form onSubmit={(e)=>{
                        e.preventDefault();

                        const recipientEmail = 'whalefrat@gmail.com';  
                        const subject = document.getElementById('subject').value;
                        const body = document.getElementById('message').value;
                    
                        const gmailComposeLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${recipientEmail}&su=${subject}&body=${body}`;
                         
                        window.open(gmailComposeLink, '_blank');
                    }
                } className="form-part">
                    <h4>Send Me EMail</h4>
                    <div className="form-group">
                        <label htmlFor="subject">Subject</label>
                        <input type="text" id="subject" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea id="message" cols="30" rows="5"></textarea>
                    </div>
                    <div className="form-group">
                        <PrimaryButton btn={'Go To Gmail'} />
                    </div>
                </form>
            </InnerLayout>
        </ContactPageStyled>
    )
}

const ContactPageStyled = styled.div`
    .contact-section{
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap:3rem;
        @media screen and (max-width:768px){
            grid-template-columns: repeat(1, 1fr);
        }
        .contact-info{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            width:100%;
            h4{
                padding: 1rem 0;
                font-size:1.8rem;
                @media screen and (max-width:600px){
                    font-size:1.5rem;
                }
            }
        }
        .form-part{
            width:100%;
            h4{
                padding: 1rem 0;
                font-size:1.8rem;
                @media screen and (max-width:600px){
                    font-size:1.5rem;
                }
            }
            .form-group{
                margin-top:2rem;
                position: relative;
                width:100%;
                label{
                    position: absolute;
                    left:0px;
                    top: -17px;
                    display: inline-block;
                    background-color: var(--sidebar-color);
                    border-radius: .5rem;
                    padding: 0 .5rem;
                    color: inherit;
                }
                input{
                    border: 1px solid var(--border-color-2);
                    outline: none;
                    background-color: var(--sidebar-color);
                    padding: 10px 15px;
                    width:100%;
                    color: inherit;
                    border-radius: .2rem;
                }
                textarea{
                    resize: none;
                    background-color: var(--sidebar-color);
                    border: 1px solid var(--border-color-2);
                    outline: none;
                    color: inherit;
                    width: 100%;
                    padding: .8rem 1rem;
                    border-radius: .2rem;
                }
            }
        }
    }
`;

export default ContactPage;
