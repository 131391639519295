import styled from 'styled-components';

export const MainLayout = styled.div`
    padding:3rem 3rem;
    @media screen and (max-width:1200px){
        padding:3rem 1rem;
    }
`;

export const InnerLayout = styled.div`
    margin:3rem 0;
`;