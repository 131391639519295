import { useState, useEffect } from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { NavLink } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

function Navbar({ setNavToggle }) {
  const [theme, setTheme] = useState('dark-mode');
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    document.documentElement.className = theme;
  }, [theme])

  const themeMode = () => {
    if (theme === 'light-mode') {
      setTheme('dark-mode');
      setChecked(false)
    } else {
      setTheme('light-mode');
      setChecked(true)
    }
  }

  const MaterialUISwitch = styled(Switch)(({ checked }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: !checked ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: !checked ? '#003892' : '#001e3c',
      width: 32,
      height: 32,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: !checked ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));

  return (
    <NavbarStyled>
      <div className="nav-logo">
        <div className="nav-logo-image"></div>
      </div>
      <div className="close-bar">
        <IconButton onClick={() => setNavToggle(false)}>
          <CloseIcon />
        </IconButton>
      </div>
      <ul className="nav-items">
        <li><NavLink to="/" exact activeClassName="active-menu" onClick={() => setNavToggle(false)}>Home</NavLink></li>
        <li><NavLink to="/about" exact activeClassName="active-menu" onClick={() => setNavToggle(false)}>About</NavLink></li>
        <li><NavLink to="/resume" exact activeClassName="active-menu" onClick={() => setNavToggle(false)}>Resume</NavLink></li>
        <li><NavLink to="/contact" exact activeClassName="active-menu" onClick={() => setNavToggle(false)}>Contact</NavLink></li>
      </ul>

      {/* Toggle Button for light and dark theme*/}
      <div>
        <div className={'light-dark-mode theme'}>
            <FormControlLabel
              control={<MaterialUISwitch
                checked={checked}
                onClick={themeMode}
                inputProps={{ 'aria-label': 'controlled' }}/>}
              label=""
            />
           
        </div>
      </div>

      <footer>
        <p></p>
      </footer>
    </NavbarStyled>
  )
}

const NavbarStyled = styled.header`
  height: 100%;
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-right:2px solid var(--border-color);

  /*Floating Toggler*/
  .light-dark-mode{ 
      background: var(--sidebar-color);
      width:6.0rem;
      height:2.5rem;
      z-index: 15;
      display:flex;
      align-items: center;
      justify-content: flex-end;
      opacity: 0.8;
      transition: all .4s ease-in-out;
      transform:translateX(100%);
      z-index:20;
      a{
        hover:cursor-pointer;
        hover:opacity-70;
        fill: rgb(var(--text-2-color)/1);
        svg{
          display:flex;
          width:1.5rem;
          height:1.5rem;
          align-items: center;
          font-size:1.5rem;
        }
      }
  }
  .theme{
      transform:translateX(0%);
      z-index:20;
  }

  /* Toggler Menu */
  .close-bar{
      position: absolute;
      right:0.9rem;
      top:0%;
      z-index:20;
      display:none;
      transition: background-color 0.15s linear;
      svg{
          font-size:2rem;
          color:var(--button-color);
      }
  }
  .close-bar:active {
    background-color: rgba(66, 143,	177, 0.5);
  }

  @media screen and (max-width:1200px){
      .close-bar{
          display:block;
      }
  }

  .nav-items{
    width:100%;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content:center;
    text-align: center;
    .active-menu{
        color: var(--primary-color);  
    }
    li{
      display: block;
      width:75%;
      a{
        font-size:1rem;
        text-transform: uppercase;
        transition: all .4s ease-in-out;
        font-weight: 600;
        letter-spacing: 1px;
        padding: 0.45rem 0;
        display: block;
        border-radius: 20px;
        &:hover{ 
            border-radius: 20px;
            background: var(--primary-color);
            cursor: pointer;
            color:var(--sidebar-color);
        }
      }
    }
  }
  
  footer{
        border-top:1px solid var(--border-color);
        width: 100%;
        p{
            padding: .3rem 0;
            font-size: 0.75rem;
            font-color:var(--font-color);
            lint-height: 1rem;
            font-weight: 400;
            font-family: 'Prompt', sans-serif;
            text-align:center;
        }
  }
`;

export default Navbar;
